import { Link } from 'react-router-dom';
import React from 'react';
import Card from './Card/Card';
import photo1 from '../../../images/grid1.jpg';
import photo2 from '../../../images/grid2.jpg';
import photo3 from '../../../images/grid3.jpg';
import photo4 from '../../../images/grid4.jpg';
import photo5 from '../../../images/grid5.jpg';
import photo6 from '../../../images/grid6.jpg';
import photo7 from '../../../images/grid7.jpg';
import photo8 from '../../../images/grid8.jpg';

const workCards = [
  {
    name: 'Уникальные вазы для комнатных растений и цветочных композиций.',
    image: photo1,
    text: 'Ваза изготавливается из биоразлагаемого пластика PLA. На выбор предлагается широкий размерный ряд диаметром от 60 до 340 мм и высотой до 120 мм. Такие габариты позволяют создавать как небольшие вазочки для букетика, так и крупные ёмкости для высоких растений и композиций.',
  },
  {
    name: 'Увеличенная фигурка лего-человека.',
    image: photo2,
    text: 'Имеет размер 410 миллиметров и вес 800 грамм. Отлично подойдёт в качестве подарка ребёнку. Выполнена из пластика PETG, который не боится ультрафиолетового излучения, перепадов температур и имеет плотную и твердую структуру. Модель не имеет мелких частей, имеет относительно маленькую массу, и высокую конструкционную прочность, поэтому подоходит даже для самых маленьких.',
  },
  {
    name: 'Двухсоставной органайзер для мобильных устройств',
    image: photo3,
    text: 'Это многофункциональное изделие позволяет удобно и компактно хранить гаджеты - смартфон, смарт-часы и беспроводные наушники. Уникальный дизайн предусматривает наличие трех отсеков оптимальной формы и размеров. Таким образом, все важные устройства будут всегда под рукой. Внутренняя коробка имеет размеры 97/88/29 миллиметров. Вес: 170 грамм',
  },
  {
    name: 'Компактный двухцветный настольный органайзер изготовленный из безопасного пластика PLA.',
    image: photo4,
    text: 'Размеры: 220/90/90 миллиметров. Вес: 150 грамм. Это оптимальные габариты для размещения часто используемых канцелярских принадлежностей',
  },
  {
    name: 'Безвоздушный баскетбольный мяч.',
    image: photo5,
    text: 'Выполнен из твердого негибкого пластика. Отлично подойдёт любителям баскетбола в качестве сувенира или подарка. Имеется символика лиги NBA. Соответствует размеру 6 оригинального мяча.',
  },
  {
    name: 'Клипсы для крепления футбольной сетки на ворота.',
    image: photo6,
    text: 'Изготовлены из атмосфероустойчивого пластика, способного выдерживать знакопеременную и ударную нагрузку в течение многих циклов. Отвечает всем требованиям ГОСТ Р55664-2013 «Оборудование для спортивных игр». Имеет сложное неполное строение внутреннего контура, что позволяет прикладывать меньше усилий для установки на ворота, и держать большую нагрузку при ударе по сетке. Поставляем в компании по производству профессионального спортивного оборудования.',
  },
  {
    name: 'Органайзер с крышкой',
    image: photo7,
    text: 'Корпус состоит из двух частей - основного вместительного отсека и съемной крышки. Крышка надежно защищает содержимое от пыли и обеспечивает компактное хранение в закрытом виде. Размеры органайзера: 200/60/80 миллиметров. Вес: 180 грамм',
  },
  {
    name: 'Ракетки для настольного тенниса.',
    image: photo8,
    text: 'Выполнены из четырех частей с использованием пластика пропиленгликоля(PETG) для ручки и термопластичного полиуретана (TPU) для резиновой поверхности. Такое сочетание материалов позволяет не использовать губчатые подкладки, которые быстро приходят в негодность, при этом сохраняя необходимые свойства ракетки.',
  }
];

export default function Portfolio () {
  return (
    <section className="portfolio" id='portfolio'>
      <div className='portfolio__container'>
        <h3 className='portfolio__title'>Наши работы</h3>
        <div className='portfolio__grid'>
          {workCards.map((work, index) => (
            <Card key={index} image={work.image} name={work.name} text={work.text} />
          ))}
        </div>
      </div>
    </section>
  )
}